export interface PointFeature {
  id?: number;
  type: string;
  geometry: {
      type: string;
      coordinates: [number, number];
  }
  properties?: {
      name?: string;
      description?: string;
  }
}

export interface PointFeatures {
    type: string;
    features: PointFeature[];
}

export class Point {
    private _id?: number;

    constructor(
        public location: [number, number], public name: string="", public description: string=""
    ) {}

    get id(): number | undefined {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    public toJson(): PointFeature {
        return {
            "id": this.id,
            "type": "Feature",
            "geometry" : {
                "type": "LineString",
                "coordinates": this.location
            },
            "properties": {
                "name": this.name,
                "description": this.description
            }
        }
    }
}
