import { Component, Injector } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MatCardModule } from '@angular/material/card';
import { createCustomElement } from '@angular/elements';
import { MarkerContentComponent } from './pages/map/marker-content/marker-content.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatCardModule,
    FooterComponent,
    HeaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'northeast-walls';

  constructor(
    injector: Injector
  ) {
    const MarkerEle = createCustomElement(MarkerContentComponent, {injector});
    customElements.define('advanced-marker-content', MarkerEle);
  }
}
