<mat-sidenav-container class="map-container" autosize>
  <mat-sidenav #sidenav class="map-controls" mode="side" position="end" opened>
    <app-map-controls />
  </mat-sidenav>

  <mat-sidenav-content>
    <google-map #map
      height="92vh"
      width="auto"
      [options]="mapOptions" />
    <button class="map-toggle" mat-button (click)="sidenav.toggle()">Drawing Tools</button>
  </mat-sidenav-content>

</mat-sidenav-container>
