import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Point } from '@shared/models/point';
import { Segment } from '@shared/models/segment';
import { MapControlsComponent } from './map-controls/map-controls.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '@shared/auth/auth.service';
import { MapDataService } from './map-data.service';
import { MarkerContentService } from './marker-content/marker-content.service';
import { environment } from '../../../env/environment';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    GoogleMap,
    MapControlsComponent,
    MatSidenavModule,
    MatButtonModule
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements AfterViewInit {
  @ViewChild(GoogleMap) wallMap!: GoogleMap;
  private map!: google.maps.Map;
  public mapZoom: number = 14;
  public maxZoom!: number;
  public zoomService!: google.maps.MaxZoomService;
  public drawingManager!: google.maps.drawing.DrawingManager;
  public points: Point[] = [];
  public segments: Segment[] = [];
  public mapOptions: google.maps.MapOptions = {
    center: { lat: environment.home.location[1], lng: environment.home.location[0] },
    zoom: this.mapZoom,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    disableDefaultUI: true,
    mapId: "DEMO_MAP_ID"
  };

  constructor(
    private auth: AuthService,
    private mapData: MapDataService,
    public markerContent: MarkerContentService
  ) {}

  ngAfterViewInit(): void {
    let options: google.maps.MapOptions = {
      center: { lat: environment.home.location[1], lng: environment.home.location[0] },
      zoom: this.mapZoom,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
      mapId: "DEMO_MAP_ID"
    };
    this.map = this.wallMap.googleMap!;
    // this.map.setOptions(options);
    this.drawingManager = new google.maps.drawing.DrawingManager({ map: this.map, drawingControl: false });
    this.zoomService = new google.maps.MaxZoomService();
    this.setMaxZoom();
    this.setPoints();
    this.setSegments();

    // Add listeners
    google.maps.event.addListener(this.map, 'center_changed', () => {
      this.setMaxZoom();
    });
    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.mapZoom = this.map.getZoom()!;
    });
    google.maps.event.addListener(this.drawingManager, 'polylinecomplete', (overlay: google.maps.Polyline) => {
      this.addSegment(overlay);
    });
  }

  public setCenter(lat: number, lon: number) {
    this.map.setCenter(new google.maps.LatLng(lat, lon));
  }

  public setZoom(zoom: number) {
    if (zoom > this.maxZoom) { this.mapZoom = this.maxZoom; }
    else if (zoom < 0) { this.mapZoom = 0; }
    else { this.mapZoom = zoom; }
    this.map.setZoom(this.mapZoom);
  }

  public getCenter() {
    return this.map.getCenter();
  }


  public setDrawingMode(mode: google.maps.drawing.OverlayType | null) {
    this.drawingManager.setDrawingMode(mode);
  }

  private setMaxZoom() {
    let self = this;
    this.zoomService.getMaxZoomAtLatLng(this.wallMap.getCenter()!, function (response) {
      self.maxZoom = response.zoom;
    });
  }

  // Can I move this to the service and return the array?
  private setPoints() {
    this.mapData.getPoints().subscribe({
      next: (data) => {
        data.features.forEach(element => {
          let point = new Point(element.geometry.coordinates, element.properties?.name, element.properties?.description);
          point.id = element.id!;
          this.points.push(point);
        });
        this.renderPoints();
      }
    });
  }

  private renderPoints() {
    let self = this;
    this.points.forEach(element => {
      let marker = new google.maps.marker.AdvancedMarkerElement({
        position: {lat: element.location[1], lng: element.location[0]},
        map: this.map,
        content: this.markerContent.getAsElement(element)
      });
      google.maps.event.addListener(marker, 'click', function() {
        // self.map.panTo({lat: element.location[1], lng: element.location[0]});
      });
    });
  }

  private setSegments() {
    this.mapData.getSegments().subscribe({
      next: (data) => {
        data.features.forEach(element => {
          let segment = new Segment(element.geometry.coordinates, element.properties?.name, element.properties?.description);
          segment.id = element.id!;
          segment.setPoints(element.properties?.points);
          this.segments.push(segment);
        });
        this.renderSegments();
      }
    });
  }

  private renderSegments() {
    this.segments.forEach(element => {
      let coordinates: {lat: number, lng: number}[] = [];
      element.coordinates.forEach(coordinate => {
        coordinates.push({lat: coordinate[1], lng: coordinate[0]});
      });
      var segment = new google.maps.Polyline({
        path: coordinates,
        geodesic: true
      });
      segment.setMap(this.map);
      google.maps.event.addListener(segment, 'click', () => {
        console.log(element);
      });
    });
  }

  // Adjust to not be concerned with bulk add
  private addSegment(polyline: google.maps.Polyline) {
    let newCoordinates: [number, number][] = [];
    polyline.getPath().getArray().forEach(function(coordinate) {
      let coordinates = coordinate.toJSON();
      newCoordinates.push([coordinates.lng, coordinates.lat]);
    });
    const segment = new Segment(newCoordinates);
    this.mapData.saveSegment(segment.toJson()).subscribe({
      next: (data) => {
        const newSegment = new Segment(data.geometry.coordinates);
        newSegment.id = data.id!;
        this.segments.push(segment);
        console.log(this.segments);
      }
    });
  }
}
