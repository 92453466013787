import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PointFeature, PointFeatures } from '@models/point';
import { SegmentFeature, SegmentFeatures } from '@models/segment';
import { RegionFeature, RegionFeatures } from '@models/region';
import { AuthService } from '@shared/auth/auth.service';
import { environment } from '../../../env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapDataService {
  private HttpOptions: any;
  private headers = new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient, private auth: AuthService) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json'
      }),
      observe: 'body'
    };
   }

  public getPoints() {
    return this.http.get<PointFeatures>(environment.baseUrl + 'walls/points');
  }

  public getRegions() {
    return this.http.get<RegionFeatures>(environment.baseUrl + 'walls/regions');
  }

  public getSegments() {
    return this.http.get<SegmentFeatures>(environment.baseUrl + 'walls/segments');
  }

  public saveSegment(segment: SegmentFeature): Observable<SegmentFeature> {
    return this.http.post<SegmentFeature>(environment.baseUrl + 'walls/segments/', JSON.stringify(segment), {headers: this.headers, observe: 'body', responseType: 'json'});
  }

  public saveRegion(region: RegionFeature) {
    this.http.post<RegionFeature>(environment.baseUrl + 'walls/regions/', JSON.stringify(region), this.HttpOptions).subscribe(
      res => {console.log(res)},
      err => {console.log(err)}
    );
  }

  public savePoint(point: PointFeature) {
    this.http.post<PointFeature>(environment.baseUrl + 'walls/points/', JSON.stringify(point), this.HttpOptions);
  }

  public deletePoint(id: number) {
    this.http.delete(environment.baseUrl + 'walls/points/' + id + '/', this.HttpOptions).subscribe({
      next: () => {}
    });
  }
}
