<mat-card class="drawing-tools">
  <mat-card-header class="map-tools-header">Drawing Tools</mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="3" rowHeight="4:3">
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="setDrawingMode(null)"><span class="material-icons">pan_tool</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="[setDrawingMode(OverlayTypes.POLYLINE)]"><span class="material-icons">timeline</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="[setDrawingMode(OverlayTypes.MARKER)]"><span class="material-icons">location_on</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class=""><span class="material-icons-outlined">hexagon</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class=""><span class="material-icons">edit</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class=""><span class="material-icons">delete</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="3" rowspan="1">
        <button id="saveButton" mat-button>Save</button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-card class="drawing-tools">
  <mat-card-header class="map-tools-header">Go To Location</mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="3" rowHeight="4:3">
      <mat-grid-tile colspan="3" rowspan="2">
        <mat-form-field>
          <mat-label>Latitude</mat-label>
          <input matInput [(ngModel)]="latitude" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3" rowspan="2">
        <mat-form-field>
          <mat-label>Longitude</mat-label>
          <input matInput [(ngModel)]="longitude" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="[gotoHome()]"><span class="material-icons">home</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="[]"><span class="material-icons">close</span></button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-button class="" (click)="[setCenter()]"><span class="material-icons">arrow_forward</span></button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>