import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Point } from '@models/point';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-point-detail',
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule
  ],
  templateUrl: './point-detail.component.html',
  styleUrl: './point-detail.component.css'
})
export class PointDetailComponent {
  @Input() pt!: Point;

  constructor() {}
}
