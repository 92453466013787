import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if(req.headers.has('skip')) {
    req = req.clone({
      headers: req.headers.delete('skip')
    });
  } else if (inject(AuthService).isAuthorized()) {
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + inject(AuthService).getToken()!.token
      }
    });
  }
  return next(req);
};
