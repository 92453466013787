import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token, TokenInterface } from '@models/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  hasError = false;
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      "Content-type": "application/json",
      "Accept": "application/json"
    });
  }

  login(email: string, password: string): boolean {
    let resp = this.http.post<TokenInterface>(
      'https://walls.bertburt.com/api/token/',
      {"username": email, "password": password},
      {
        headers: this.headers.append("skip", "true")
      })
        .subscribe({
          next: (res) => this.cacheToken(Token.fromJson(res)),
          error: () => { this.hasError = true; }
        });
     if (this.hasError) return false;

     return true;   
  }

  private cacheToken(token: Token) {
    // change back to Token model after figuring out CSRF
    localStorage.setItem('token', JSON.stringify(token.toJson()));
  }

  public isAuthorized(): boolean {
    const token = this.getToken();

    if (token) {
      let currentTime = new Date().getTime();
      let expiryTime = new Date(token.expiry).getTime();
      return expiryTime >= currentTime ? true : false;
    }
    return false;
  }

  public getToken(): Token | null {
    const storage = localStorage.getItem('token');
    return storage ? JSON.parse(storage) as Token : null;
  }
}
