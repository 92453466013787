import { User, UserInterface } from "./user";

export class Token {
  constructor(
    public token: string,
    public expiry: string,
    public user: User
  ) {}

  public toJson(): TokenInterface {
    return {
      "expiry": this.expiry,
      "token": this.token,
      "user": this.user.toJson()
    }
  }

  static fromJson(token: TokenInterface): Token {
    return new Token(token.token, token.expiry, User.fromJson(token.user));
  }
}

export interface TokenInterface {
  token: string;
  expiry: string;
  user: UserInterface;
}