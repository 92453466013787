export class User {

    constructor(
        public username: string, public email: string
    ) {}

    public toJson(): UserInterface {
      return {
        "username": this.username,
        "email": this.email
      }
    }

    static fromJson(user: UserInterface): User {
      return new User(user.username, user.email);
    }
}

export interface UserInterface {
  id?: number;
  username: string;
  email: string;
}
