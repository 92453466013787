import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Point } from '@models/point';

@Component({
  selector: 'app-point-form',
  standalone: true,
  imports: [
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './point-form.component.html',
  styleUrl: './point-form.component.css',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PointFormComponent implements OnInit {
  @Input() pt!: Point;
  form!: FormGroup;
  formBuilder = inject(FormBuilder);
  ctrlContainer = inject(FormGroupDirective);

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('pointForm', this.formBuilder.group({
      name: [this.pt?.name, Validators.required],
      description: [this.pt?.description]
    }));
  }

  get isValid() {
    return this.form.valid;
  }
}
