import { Component, inject, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Point } from '@models/point';
import { PointDetailComponent } from '@shared/components/point-detail/point-detail.component';
import { PointFormComponent } from '@shared/forms/point-form/point-form.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-marker-content',
  standalone: true,
  imports: [PointDetailComponent, PointFormComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './marker-content.component.html',
  styleUrl: './marker-content.component.css',
  animations: [
    trigger('showDetails', [
      state('show', style({
          opacity: '1'
        })
      ),
      state('hide', style({
          opacity: '0'
        })
      ),
      transition('show <=> hide', [animate('300ms ease-out')])
    ])
  ]
})
export class MarkerContentComponent {
  @Input() pt!: Point;
  ptForm: FormGroup;

  hideDetails = true;
  editMode = true;

  constructor() {
    this.ptForm = new FormGroup({});
  }

  public toggle() {
    this.hideDetails = this.hideDetails ? false : true;
    // console.log(this.pt);
  }

  public toggleEdit() {
    this.editMode = this.editMode ? false : true;
  }
}
