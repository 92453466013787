import { Point, PointFeature, PointFeatures } from './point';

export interface SegmentFeature {
  id?: number;
  type: string;
  geometry: {
      type: string;
      coordinates: [number, number][];
  }
  properties?: {
      name?: string;
      description?: string;
      points?: {
          type: string;
          features: PointFeature[];
      }
  }
}

export interface SegmentFeatures {
  type: string;
  features: SegmentFeature[];
}

export class Segment {
    private _id?: number;

    constructor(
        public coordinates: [number, number][], public name: string="", public description: string="", public points: Point[] = []
    ) {}

    get id(): number | undefined {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    public setPoints(points: PointFeatures | undefined) {
      if (points) {
        points.features.forEach(element => {
            let point = new Point(element.geometry.coordinates, element.properties?.name, element.properties?.description);
            //point.id = element.id;
            //this.points[point.id] = point;
        });
      }
    }

    public toJson(): SegmentFeature {
        return {
            "id": this.id,
            "type": "Feature",
            "geometry" : {
                "type": "LineString",
                "coordinates": this.coordinates
            },
            "properties": {
                "name": this.name,
                "description": this.description
            }
        }
    }
}
