import { AfterViewInit, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MapComponent } from '../map.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../../env/environment';

@Component({
  selector: 'app-map-controls',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './map-controls.component.html',
  styleUrl: './map-controls.component.css'
})
export class MapControlsComponent implements AfterViewInit {
  public latitude: number;
  public longitude: number;
  public OverlayTypes!: typeof google.maps.drawing.OverlayType;

  constructor(
    private baseMap: MapComponent
  ) {
    this.latitude = environment.home.location[1];
    this.longitude = environment.home.location[0];
  }

  ngAfterViewInit(): void {
    this.OverlayTypes = google.maps.drawing.OverlayType;
  }

  setCenter() {
    this.baseMap.setCenter(this.latitude, this.longitude);
  }

  clearLatLon() {
    this.latitude = environment.home.location[1];
    this.longitude = environment.home.location[0];
  }

  gotoHome() {
    this.baseMap.setCenter(environment.home.location[1], environment.home.location[0]);
  }

  zoomIn() {
    this.baseMap.setZoom(this.baseMap.mapZoom + 1);
  }

  zoomOut() {
    this.baseMap.setZoom(this.baseMap.mapZoom - 1);
  }

  setDrawingMode(mode: google.maps.drawing.OverlayType | null) {
    this.baseMap.setDrawingMode(mode);
  }
}
