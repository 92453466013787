<ng-container formGroupName="pointForm">
  <mat-card class="pointForm">
    <mat-card-content>
      <p>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input type="text" id="name" formControlName="name" matInput placeholder="Name" />
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea type="text" id="description" formControlName="description" matInput placeholder="Add a description..."></textarea>
        </mat-form-field>
      </p>
    </mat-card-content>
  </mat-card>

  <!--p @if="error" class="error">
    {{ error }}
  </p-->

  <!--div class="button">
    <button type="submit" [disabled]="!pointForm.valid" mat-button>Save</button>
  </div-->
</ng-container>
