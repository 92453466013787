import { Injectable } from '@angular/core';
import { Point } from '@models/point';

@Injectable({
  providedIn: 'root'
})
export class MarkerContentService {

  constructor() { }

  getAsElement(pt: Point) {
    const ptDetail = document.createElement('advanced-marker-content');

    ptDetail.pt = pt;
    return ptDetail;
  }
}
