<div class="marker">
  <div class="details" [@showDetails]="hideDetails ? 'hide' : 'show'"
    (@showDetails.start)="$event.element.style.display = 'block'"
    (@showDetails.done)="$event.element.style.display = ($event.toState === 'show' ? 'block' : 'none')">
    @if (editMode) {
      <form [formGroup]="ptForm">
        <app-point-form [pt]="pt"></app-point-form>
    </form>
    } @else {
      <app-point-detail [pt]="pt"></app-point-detail>
    }
  </div>
  <div class="toggle-details" (click)="toggle()">
    @if (hideDetails) {
      <span class="material-icons expand">keyboard_arrow_up</span>
    } @else {
      <span class="material-icons expand">keyboard_arrow_down</span>
    }
  </div>
</div>
