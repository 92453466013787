<mat-card>
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
    <p>
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input type="text" matInput placeholder="Username" formControlName="username">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="password">
      </mat-form-field>
    </p>

    <p *ngIf="error" class="error">
      {{ error }}
    </p>

    <div class="button">
      <button type="submit" [disabled]="!form.valid" mat-button>Login</button>
    </div>

    </form>
  </mat-card-content>
</mat-card>